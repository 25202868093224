<i18n>
ru:
  apartment: Кв./Офис
  corpus: Строение/корп.
  house: Дом
  street: Улица
  streetError: Адрес не найден.
ua:
  apartment: Кв./Офіс
  corpus: Корпус
  house: Будинок
  street: Вулиця
  streetError: Адресу не знайдено.
us:
  apartment: Apt./Office
  corpus: Housing
  house: House
  street: Street
  streetError: Address not found.
</i18n>

<template>
  <div
    class="v-row v-main-form-address-custom"
    :class="{
      'not-all-address': stringIsNullOrWhitespace(street) || stringIsNullOrWhitespace(house)
    }"
  >
    <div class="v-col-12 v-col-sm-6">
      <common-typeahead-input
        v-if="appConfig.RestaurantSettingsPreRun.LoadStreets"
        required
        v-form-validator="{
          Form: form,
          Value: isUserStreetCorrect ? '#' : '',
          Required: true,
          Validations: []
        }"
        :input="userStreet"
        :label="translate('streetListAutocomplete.street')"
        :min-chars="appConfig.VueSettingsPreRun.StreetListAutocompleteMinChars"
        :user-input-changed="userInputStreetChanged"
        :zero-results="translate('yandexMapsAutocomplete.incorrectStreet')"
        data-test-id="order-user-street"
      />
      <v-input
        v-else
        class-name="v-arora-input"
        required
        v-form-validator="{
          Form: form,
          Value: street,
          Required: true,
          Validations: ['length'],
          MaxLength: 200
        }"
        :label="translate('streetListAutocomplete.street')"
        data-test-id="order-user-street"
        v-model:text="street"
      />

      <div
        v-if="error"
        class="v-error-color"
        v-html="error"
      />
    </div>
    <div class="v-col-6 v-col-md-3">
      <v-input
        class-name="v-arora-input"
        required
        v-form-validator="{
          Form: form,
          Value: house,
          Required: true,
          Validations: ['length'],
          MaxLength: 10
        }"
        :label="translate('streetListAutocomplete.house')"
        data-test-id="order-user-house"
        v-model:text="house"
      />
    </div>
    <div
      v-if="!appConfig.VueSettingsPreRun.CorpusHidden"
      class="v-col-6 v-col-md-3"
    >
      <v-input
        class-name="v-arora-input"
        v-form-validator="{
          Form: form,
          Value: corpus,
          Required: appConfig.VueSettingsPreRun.CorpusRequired,
          Validations: ['noSpace', 'length'],
          MaxLength: 10
        }"
        :label="translate('streetListAutocomplete.corpus')"
        :required="appConfig.VueSettingsPreRun.CorpusRequired"
        data-test-id="order-user-corpus"
        v-model:text="corpus"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AccountOverridableAddress } from '~types/accountStore'
import type { Street } from '~types/addressStore'
import type { TypeaheadOptions } from '~types/common'
import type { HasValidatorForm } from '~types/props'

import { type GUID, useCommon, vFormValidator, VInput } from '@arora/common'

const {
  deliveryAddress,
  saveDeliveryAddress,
  form = 'cart'
} = defineProps<AccountOverridableAddress & HasValidatorForm>()

const { translate } = useI18nSanitized()

const clientStore = useClientStore()
const addressStore = useAddressStore()
const { debounce, stringIsNullOrWhitespace } = useCommon()
const appConfig = useAppConfig()

const error = ref<string | null>(null)

const streets = computed<Street[]>(() => {
  const streets = addressStore?.StreetsList?.data ?? []

  return streets.length > appConfig.VueSettingsPreRun.StreetListAutocompleteNumber
    ? streets.slice(appConfig.VueSettingsPreRun.StreetListAutocompleteNumber)
    : streets
})

const street = computed<string>({
  get: () => {
    if (deliveryAddress) {
      return deliveryAddress.Street
    }

    return clientStore.ClientState?.data?.SelectedDeliveryAddress?.Street ?? ''
  },
  set: (value) => {
    if (saveDeliveryAddress) {
      saveDeliveryAddress({
        Street: value ?? ''
      })
    } else {
      debounce(() =>
        clientStore.updateDeliveryAddressSingle({
          street: value ?? ''
        })
      )
    }
  }
})
const house = computed<string>({
  get: () => {
    if (deliveryAddress) {
      return deliveryAddress.House
    }

    return clientStore.ClientState?.data?.SelectedDeliveryAddress?.House ?? ''
  },
  set: (value) => {
    if (saveDeliveryAddress) {
      saveDeliveryAddress({
        House: value ?? ''
      })
    } else {
      debounce(() =>
        clientStore.updateDeliveryAddressSingle({
          house: value ?? ''
        })
      )
    }
  }
})
const corpus = computed<string>({
  get: () => {
    if (deliveryAddress) {
      return deliveryAddress.Corpus
    }

    return clientStore.ClientState?.data?.SelectedDeliveryAddress?.Corpus ?? ''
  },
  set: (value) => {
    if (saveDeliveryAddress) {
      saveDeliveryAddress({
        Corpus: value ?? ''
      })
    } else {
      debounce(() =>
        clientStore.updateDeliveryAddressSingle({
          corpus: value ?? ''
        })
      )
    }
  }
})

const userStreet = ref<string>('')
const isUserStreetCorrect = ref<boolean>(false)

async function userInputStreetChanged(value: string): Promise<TypeaheadOptions<null>[]> {
  isUserStreetCorrect.value = false
  userStreet.value = value

  if (value.length < appConfig.VueSettingsPreRun.StreetListAutocompleteMinChars) {
    return []
  }

  if (!clientStore.ClientState?.data?.SelectedDeliveryAddress?.DepartmentID) return []

  let city, department
  if (deliveryAddress) {
    department = deliveryAddress.CloserDepartmentID
    city = deliveryAddress.CityID
  } else {
    department = clientStore.ClientState?.data.SelectedDeliveryAddress.DepartmentID
    city = clientStore.ClientState?.data.SelectedDeliveryAddress.CityID
  }

  await addressStore.loadStreetList(
    department as GUID,
    value,
    appConfig.VueSettingsPreRun.StreetListAutocompleteMergeStreets ? undefined : city
  )

  return streets.value.map((item: Street) => {
    return {
      ID: item.value as GUID,
      title: item.value,
      item: null,
      onClick: () => {
        selectNewStreet(item.value)
        street.value = item.value
        isUserStreetCorrect.value = true
      }
    }
  })
}
async function selectNewStreet(value: string): Promise<void> {
  if (saveDeliveryAddress) {
    saveDeliveryAddress({
      Street: value
    })
  } else {
    await clientStore.updateDeliveryAddressSingle({
      street: value
    })
  }
}

onMounted(() => {
  clientStore.initClientState()

  if (deliveryAddress) {
    userStreet.value = deliveryAddress.Street
  } else if (clientStore.ClientState?.data?.SelectedDeliveryAddress?.Street) {
    userStreet.value = clientStore.ClientState?.data?.SelectedDeliveryAddress?.Street
  }

  isUserStreetCorrect.value = !stringIsNullOrWhitespace(userStreet.value)
})

watch(isUserStreetCorrect, (current) => {
  if (current) userStreet.value = street.value
})

watch(street, (current) => {
  userStreet.value = current
  isUserStreetCorrect.value = !stringIsNullOrWhitespace(current)
})
</script>
